<template>
  <dokbot-component
    :inputs="inputs"
    :time-id="timeId"
    :workflow-id="workflowId"
    is-preview-mode
  />
</template>

<script>
import { mapGetters } from 'vuex'
import DokbotComponent from '@/components/DokbotComponent'

export default {
  components: { DokbotComponent },
  data() {
    return {
      dialog: false,
      timeId: this.$route.query.timeId,
      inputs: this.$route.query,
      session: null,
      workflowId: this.$route.params.workflowId
    }
  },
  computed: {
    ...mapGetters('clients', ['client'])
  },
  methods: {}
}
</script>

<style>
html,
body,
main,
main > div {
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: radial-gradient(#ffffff);
}

@media only screen and (min-width: 600px) {
  main > div {
    height: 100%;
    transform: translateY(100%);
  }
  .dokbot-component {
    margin: 0 auto;
    transform: translateY(50%);
  }
}
@media only screen and (max-width: 599px) {
  .dokbot-component {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100) !important;
  }
}
</style>
